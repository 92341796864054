<template>
  <div class="sidebar">
    <b-row class="header-image">
      <b-col>
        <span>
          <a href="">
            <img
                :style="'width: ' + widthImg"
                alt="Schaffen"
                src="../../assets/schaffen.png"
            />
          </a>
        </span>
      </b-col>
    </b-row>
    <b-row class="menu-row" cols="1">
      <b-col class="menu-col">
       <!-- <Collapse :buttons="cadastroButtons" title="cadastro">
          <b-icon-tools/>
        </Collapse>
        <Collapse :buttons="nfeButtons" title="NFes">
          <b-icon-file-play/>
        </Collapse>
        <Collapse :buttons="nfscNfstButtons" title="NFSC/NFST">
          <b-icon-card-list/>
        </Collapse> -->
        <Collapse :buttons="ordemProducaoButtons" title="Ordem Produção">
          <b-icon-card-list/>
        </Collapse>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Collapse from '@/components/index/Collapse';

export default {
  name: 'Sidebar',
  components: {
    Collapse,
  },
  props: {
    widthImg: String,
  },
  data: function () {
    return {
      fixedMenu: 'fixed-menu',
      fixedText: 'Fixar Menu',
      rotateSvg: '',
      rotateClick: false,
      login: '',
      cadastroButtons: [
        {text: 'Campos', route: 'campos'},
        {text: 'Formulários', route: 'formularios'},
        {text: 'Grupos de Formulários', route: 'agrupamento_formularios'},
        {text: 'Motivos Visita', route: 'motivo_visita'},
      ],
      nfeButtons: [{text: 'NFes Importadas', route: 'nfes'}],
      nfscNfstButtons: [
        {text: 'Emitir NFSC/NFST', route: 'emitir_nfsc_nfst'},
        {text: 'Arquivos Gerados', route: 'arquivos_nfsc_nfst'},
      ],
      ordemProducaoButtons: [
        {text: 'Cadastro de Composicao', route: 'cadastro_composicao'},
        {text: 'Ordem de Produção', route: 'ordem_producao'},
        //{text: 'Kanban', route: 'kanban_producao'},
      ],
    };
  },
  methods: {},
};
</script>

<style scoped>
.sidebar {
  min-height: 100%;
  min-width: 280px;
  border-right: solid 1px #c31d1f;
  transition: left 1s, width 1s;
}

img {
  width: 10em;
  padding: 0.5em 0.2em 0.2em 0.2em;
  transition: width 1s;
}

.menu-row {
  /*margin-top: 50vh;*/
  /*transform: translate(0, -50%);*/
}

.menu-col {
  padding-top: 4em;
}
</style>
